import React, { useState, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import cn from 'clsx';
import Lottie from 'lottie-web';
import { Link } from 'gatsby';

import Layout from 'components/Layout';
import Meta from 'components/Meta';
import FeatureContainer from '../components/FeatureContainer';
import TrustedBy from '../components/TrustedBy';
// import TestimonialsSlider from '../components/TestimonialsSlider/TestimonialsSlider';

import SubHeader from '../components/SubHeader/SubHeader';
import NewFaq from '../components/NewFaq/NewFaq';
import FeaturesGrid from '../components/FeaturesGrid/FeaturesGrid';
// import Reviews from '../components/Reviews';
import { videoAnnotationFeatures } from '../utils/contentProvider';
import { faqDataVideoAnnotation } from '../utils/contentProvider/faqDataCommon';

import 'styles/usecases.scss';
import 'styles/bug-tracking.scss';
import { btnOnClick } from '../utils/contentProvider/function';

const VideoAnnotation = ({ location }) => {
  const [showVideoModal, setVideoModal] = useState(false);
  const [activeSection, setActiveSection] = useState(null);

  function toggleVideoModal() {
    setVideoModal(!showVideoModal);
  }

  const lottieContainer = useRef();

  useEffect(() => {
    Lottie.loadAnimation({
      container: lottieContainer.current,
      path: '/assets/lotties/video-feedback.json',
      renderer: 'svg',
      loop: true,
      autoplay: true,
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      const currentPosition = window.scrollY + window.innerHeight / 2;

      sections.forEach((section) => {
        if (
          section.offsetTop <= currentPosition &&
          section.offsetTop + section.offsetHeight > currentPosition
        ) {
          setActiveSection(section.getAttribute('id'));
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    document.body.style.overflow = showVideoModal ? 'hidden' : 'auto';
  }, [showVideoModal]);

  return (
    <Layout
      location={location}
      hideTopBar
      productPage
      hideHeader
      product="Website Feedback Tool">
      <Meta
        title="Video Annotation Tool - Annotate and Collaborate Effortlessly | ruttl"
        description=" Streamline media analysis with our video annotation tool. Annotate videos with precision, use shape markup, and record screens for frame-by-frame feedback."
        url="https://ruttl.com/video-annotation-tool/"
      />
      <div
        id="demo-modal"
        className={cn(['demo-modal', { show: showVideoModal }])}>
        <button
          type="button"
          className="button-unstyled"
          onClick={toggleVideoModal}>
          <img src="/assets/img/close.svg" alt="close" title="close" />
        </button>
        {showVideoModal && (
          <iframe
            width="560"
            height="315"
            src="https://www.youtube-nocookie.com/embed/WmHna-qA0xA"
            frameBorder="0"
            title="ruttl demo"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </div>
      <SubHeader
        productType="video-annotation"
        productName="Video Annotation"
        showVideoModal
        setActiveSection={setActiveSection}
        activeSection={activeSection}
      />
      <main className="styled-main">
        <main className="features-hero">
          <div className="container">
            <div className="hero-content">
              <div className="hero-desc">
                <div className="sub-product sub-product--pdf">
                  <img src="/assets/img/add-plugin.svg" alt="" width={18} />
                  <span>
                    Video Annotation is a sub product of Website Feedback Tool
                  </span>
                </div>
                <h1>
                  Fastest video annotation tool to give feedback on media files
                </h1>
                <p className="sub-text">
                  Annotate videos with precise timestamps, shape markup, and
                  screen recording for seamless frame-by-frame feedback. Reduce
                  the likelihood of miscommunication!
                </p>

                <a
                  href="https://web.ruttl.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="get-started-free-vat-hero"
                  className="button">
                  Get started for free
                </a>
                <a
                  href="https://calendly.com/setup-demo-with-ruttl/30min"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="book-demo-vat-hero"
                  type="button"
                  className="button book-demo">
                  Book a Demo
                </a>
                <p className="sub-text-small">
                  Collaborate with your team for exceptional video creations
                </p>
              </div>
              <div className="hero-img">
                <div ref={lottieContainer}></div>
              </div>
            </div>
          </div>

          <TrustedBy />
          <section
            className="styled-bug-tracking"
            style={{ backgroundColor: '#fff' }}
            id="how-it-works">
            <div className="container">
              <div style={{ maxWidth: 544, margin: 'auto' }}>
                <h2 className="center" id="view-demo">
                  Start video annotation with 3 simple steps
                </h2>
              </div>
              <div className="flex-2 flex flex-wrap align-items-stretch">
                <div className="wrapper">
                  <div className="card-single">
                    <div className="card-block">
                      <div className="card-icon">
                        <img
                          loading="lazy"
                          src="/assets/img/upload-video-file.png"
                          alt="upload video icon"
                          title="upload video icon"
                          width={50}
                        />
                      </div>
                      <div className="card-desc">
                        <h3>Upload Video File</h3>
                        <p>
                          Upload any MP4 video file from your device onto the
                          ruttl project to start the process of video
                          annotation.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wrapper">
                  <div className="card-single">
                    <div className="card-block">
                      <div className="card-icon">
                        <img
                          loading="lazy"
                          src="/assets/img/markup-video-comments.png"
                          alt="Markup video icon"
                          title="Markup video icon"
                          width={50}
                        />
                      </div>
                      <div className="card-desc">
                        <h3>Markup Video</h3>
                        <p>
                          Click anywhere within the frame to annotate video with
                          comments, you can also highlight with shapes to share
                          your feedback.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wrapper">
                  <div className="card-single">
                    <div className="card-block">
                      <div className="card-icon">
                        <img
                          loading="lazy"
                          src="/assets/img/share-video-project.png"
                          alt="Share project icon"
                          title="Share project icon"
                          width={50}
                        />
                      </div>
                      <div className="card-desc">
                        <h3>Share project link</h3>
                        <p>
                          Communicate with your team easily by sharing a project
                          link. With just a few clicks, you can grant access to
                          your video annotation project.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="button"
                type="button"
                id="get-started-free-vat-fold2"
                onClick={btnOnClick({
                  to: 'https://web.ruttl.com/signup',
                })}>
                Get started for free
              </button>
            </div>
          </section>
        </main>

        <div className="usecases-styled-main">
          <section className="section features-main" id="features">
            <div className="container">
              <div className="section-head">
                <h2 className="center" style={{ maxWidth: 700 }}>
                  Fastest video annotation tool to give feedback on media files
                </h2>
              </div>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          loading="lazy"
                          src="/assets/img/upload-video-from-system.png"
                          alt="video annotation tool feature upload video from system"
                          title="upload-video-from-system"
                        />
                      </div>
                    </div>
                    <div className="feature-txt">
                      <h3 className="feature-head">
                        Upload videos from your system
                      </h3>
                      <p>
                        Upload videos directly from your system and start
                        providing instant feedback.
                      </p>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          loading="lazy"
                          src="/assets/img/comment-on-videos.png"
                          alt="video annotation tool feature add comments to your videos"
                          title="Add comments to your videos"
                        />
                      </div>
                    </div>
                    <div className="feature-txt">
                      <h3 className="feature-head">
                        Comment on videos directly
                      </h3>
                      <p>
                        With our video commenting tool add pixel-pinned comments
                        directly within the video, for precise, contextually
                        relevant feedback and enhance collaboration.
                      </p>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          loading="lazy"
                          src="/assets/img/freehand-shape-annotation.png"
                          alt="video annotation tool feature freehand annotation"
                          title="freehand-annotation"
                        />
                      </div>
                    </div>
                    <div className="feature-txt">
                      <h3 className="feature-head">
                        Free-hand and shape annotations
                      </h3>
                      <p>
                        Our video annotation tool allows you to add both
                        free-hand and shape annotations, thereby making it easy
                        to communicate even your most challenging feedback!
                      </p>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
            </div>
          </section>

          <FeaturesGrid
            data={videoAnnotationFeatures}
            productType="video-annotation"
          />
          {/* <TestimonialsSlider />
          <Reviews /> */}
          <section className="section-gap">
            <div className="container">
              <h2 className="center" style={{ marginBottom: 50 }}>
                Explore other products
              </h2>
              <div className="flex-row justify-content-center">
                {[
                  {
                    id: 0,
                    icon: '/assets/img/customer-feedback-icon.svg',
                    heading: 'Customer Feedback',
                    text: 'Chat with your website users in real time & resolve their issues with your team',
                    url: '/customer-feedback-tool/',
                  },
                  {
                    id: 1,
                    icon: '/assets/img/mobile-app-feedback-icon.svg',
                    heading: 'Mobile App Feedback',
                    text: 'Mark issues directly on mobile application, organise feedback using our internal workflow',
                    url: '/mobile-app-feedback/',
                  },
                ].map(({ id, icon, heading, text, url }) => (
                  <div key={id} className="col col-3 col-md-6">
                    <Link to={url} className="icon-text-box">
                      <div className="box-icon">
                        <img src={icon} alt="" />
                      </div>
                      <div className="box-desc">
                        <h3>{heading}</h3>
                        <p>{text}</p>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <NewFaq data={faqDataVideoAnnotation} />
        </div>
      </main>
    </Layout>
  );
};

VideoAnnotation.propTypes = {
  location: PropTypes.object,
};

export default VideoAnnotation;
